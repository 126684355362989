<!-- 小区奖左右区结余业绩查询 -->
<template>
  <div class="page-container account">
    <div style="text-align: center; margin-bottom: 0.2rem">
      <!-- 搜索 -->
      <el-button type="primary" :loading="btnLoading" plain @click="getList" :title="$t('pl.plate.search')">{{ $t('pl.plate.search') }}</el-button>
    </div>
    <el-table
        v-loading="tableLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <!-- 经销商编号 -->
      <el-table-column :label="$t('miMember.memberNo')" prop="memeberNo" align="center"/>
      <!-- 左区部门 -->
      <el-table-column :label="$t('left.department')" prop="leftPartNo" align="center"/>
      <!-- 左区结余业绩 -->
      <el-table-column :label="$t('right.leftPoint')" prop="leftPoint" align="center"/>
      <!-- 右区部门 -->
      <el-table-column :label="$t('right.department')" prop="rightPartNo" align="center"/>
      <!-- 右区结余业绩 -->
      <el-table-column :label="$t('right.rightPoint')" prop="rightPoint" align="center"/>
    </el-table>
  </div>
</template>

<script>
import { bonusmentMainByUser } from '@/api/rus/api'
export default {
  name: "PerformanceInquire",
  data() {
    return {
      tableLoading: false,
      btnLoading: false,
      list: []
    }
  },
  created() {
    this.getList()
  },
  methods: {
    getList() {
      this.tableLoading = true
      this.btnLoading = true
      bonusmentMainByUser().then(res => {
        this.list = res.data
        this.tableLoading = false
        this.btnLoading = false
      }).catch(() => {
        this.tableLoading = false
        this.btnLoading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
